import firebase from 'firebase'
// import 'firebase/auth'
// import 'firebase/database'

const config = {
  apiKey: 'AIzaSyCumYyL8GymAW065mYmWCmTbpG8ziIyB2s',
  databaseURL: 'https://lander-343df-default-rtdb.firebaseio.com/',
  authDomain: 'lander-343df.firebaseapp.com',
  projectId: 'lander-343df',
  storageBucket: 'lander-343df.appspot.com',
  messagingSenderId: '307827044244',
  appId: '1:307827044244:web:691925698a3100f17f2981',
  measurementId: 'G-EHQB1LX7WQ'
}

const app = firebase.initializeApp(config)
export const fireBase = firebase
export const database = firebase.database();
export default firebase
