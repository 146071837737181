import * as React from 'react'
import UIButton from '@components/UIButton'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Engineeringguide1 from '../../assets/images/Engineeringguide1.jpg'
import Engineeringguide2 from '../../assets/images/Engineeringguide2.jpg'
import Legalguide from '../../assets/images/Legalguide.jpg'
import Scienceguide1 from '../../assets/images/Scienceguide1.jpg'
import Scienceguide2 from '../../assets/images/Scienceguide2.jpg'

import {
  selectIndustries,
  selectPositions,
  updateProfileJsonRequest,
  updateProfileRequest
} from '@features/auth/authSlice'
import UITextField, { UITextFieldMask } from '@components/UITextField'
import {
  ProfileBoxWrap,
  ProfileBox,
  ProfileTextSection,
  ProfileTextSmallSection,
  ProfileTextSubTitle,
  ProfileTextTitle
} from './common'

import { JobCategoryButton, TextSubTitle } from '@screen/jobs/components'

import { ReactComponent as BookmarkIcon } from '@assets/icons/bookmark.svg'
import { ReactComponent as Bookmark2Icon } from '@assets/icons/bookmarkOutlined.svg'
import { JobActionIcon } from '@screen/jobs/JobCard'
import { candidateSaveRequest } from '@features/candidates/candidateSlice'
import UIRadio from '@components/UIRadio'
import { unwrapResult } from '@reduxjs/toolkit'
import { updateSnack } from '@features/util/utilSlice'
import { selectCategories } from '@features/jobs/jobSlice'
import { STATE_TYPES } from '@utils/phoneMask'
import { Button, Popover } from '@mui/material'
import Popup from '@components/Popup'
import { updateEmployee } from '@features/employee/employeeSlice'

const fieldValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter your first name'),
  last_name: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  contact_number: yup.string().required('Contact Number is required'),
  city: yup.string(), //.required('Please enter your city'),
  zip_code: yup.string(), //.required('Please enter your zip code'),
  state: yup.string(), //.required('Please enter your state'),
  candidate: yup.object().shape({
    currently_employed: yup.string(),
    current_company_name: yup.string(),
    current_company_industry: yup.string(),
    current_company_position: yup.string()
  }),
  company: yup.object().shape({
    business_name: yup.string(),
    business_type: yup.string(),
    position: yup.string()
    //[payment] payment_method: yup.string(),
  })
})

const ProfileDetail = ({ user, currentUser, isProfile }) => {
  const {
    id, //: 13,
    first_name, //: 'Mark',
    last_name, //: 'Zuckerberg',
    email, //: 'comp3@crowdbotics.com',
    type, //: 'Company',
    // registration_id, //: null,
    company,
    candidate,
    employee,
    saved
    // job_post_notifications, //: true,
    // interview_notifications, //: true,
    // message_notifications, //: true
  } = user || {}

  const {
    // Common
    photo, //: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/profile/photos/mark-zuck-img_WQvsglJ.jpeg',
    city, //: 'San Francisco',
    zip_code, //: '92382',
    state, //: 'California'
    contact_number, //: '1230192039',

    //Company
    business_name, //: 'Meta',
    business_type, //: 'Marketing',
    position, //: 'CEO',
    // payment_method, //: '',

    //Candidate
    // resume, //: null,
    interests, //: [],
    roles, //: [],
    current_company_industry, //: null,
    current_company_position, //: null,
    preferred_locations, //: [],
    // saved, //: [],
    currently_employed, //: null,
    current_company_name, //: null,
    video, //: null,
    resume_file //: null
  } = company || candidate || employee || {}

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    // setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name,
      last_name,
      email,
      contact_number,
      city,
      zip_code,
      state,
      candidate: {
        currently_employed,
        current_company_name,
        current_company_industry,
        current_company_position
      },
      company: {
        business_name,
        business_type,
        position
      }
    },
    resolver: yupResolver(fieldValidationSchema)
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const imgRef = React.useRef()
  const videoRef = React.useRef()
  const resumeRef = React.useRef()

  const positions = useSelector(selectPositions)
  const industries = useSelector(selectIndustries)
  const categories = useSelector(selectCategories)
  const isCandidateProfile = type === 'Candidate' || isProfile

  const [editMode, setEditMode] = React.useState(false)
  const [isSaved, setIsSaved] = React.useState(saved)

  const [currentImageFile, setCurrentImageFile] = React.useState()
  const [previewImage, setPreviewImage] = React.useState()

  const [currentVideoFile, setCurrentVideoFile] = React.useState()
  const [currentResumeFile, setCurrentResumeFile] = React.useState()
  // const [previewImage, setPreviewImage] = React.useState();

  const [interestsMap, setInterestsMap] = React.useState(
    interests?.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}) || {}
  )

  const [rolesMap, setRolesMap] = React.useState(
    roles?.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}) || {}
  )
  const [preferedLocations, setPreferedLocations] = React.useState(
    preferred_locations || []
  )
  const getRoles = roles => {
    const list = []
    roles?.forEach(element => {
      list.push({ name: element })
    })
    return list
  }
  const [rolesMapList, setRolesMapList] = React.useState(
    roles?.length > 0 ? getRoles(roles) : []
  )
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    setValue('first_name', user?.first_name)
    setValue('last_name', user?.last_name)
    setValue('email', user?.email)
    if (user?.type === 'Company') {
      setValue('contact_number', user?.company?.contact_number)
      setValue('city', user?.company?.city)
      setValue('zip_code', user?.company?.zip_code)
      setValue('state', user?.company?.state)

      setValue('company.business_name', user?.company?.business_name || '')
      setValue('company.business_type', user?.company?.business_type || '')
      setValue('company.position', user?.company?.position || '')
      //[payment] setValue('company.payment_method', user?.company.payment_method);
    } else if (type === 'Employee') {
      setValue('employee.position', user?.employee?.position || '')
      setValue(
        'company.business_name',
        user?.employee?.company?.business_name || ''
      )
      setValue(
        'company.business_type',
        user?.employee?.company?.business_type || ''
      )
      setValue('contact_number', user?.employee?.contact_number || '')
      setValue('city', user?.employee?.city || '')
      setValue('zip_code', user?.employee?.zip_code || '')
      setValue('state', user?.employee?.state || '')
    } else {
      console.log('useruser', user)
      setValue('contact_number', user?.candidate?.contact_number || '')
      setValue('city', user?.candidate?.city || '')
      setValue('zip_code', user?.candidate?.zip_code || '')
      setValue('state', user?.candidate?.state || '')

      setValue(
        'candidate.currently_employed',
        user?.candidate?.currently_employed ? 'True' : 'False'
      )
      setValue(
        'candidate.current_company_name',
        user?.candidate?.current_company_name || ''
      )
      setValue(
        'candidate.current_company_industry',
        user?.candidate?.current_company_industry || ''
      )
      setValue(
        'candidate.current_company_position',
        user?.candidate?.current_company_position || ''
      )
    }
  }, [setValue, user])
  React.useEffect(() => {
    register('first_name')
    register('last_name')
    register('email')
    register('contact_number')
    register('city')
    register('zip_code')
    register('state')
    if (type === 'Company') {
      register('company.business_name')
      register('company.business_type')
      register('company.position')
      //[payment] register('company.payment_method');
    } else if (type === 'Employee') {
      register('employee.position')
    } else {
      register('candidate.currently_employed')
      register('candidate.current_company_name')
      register('candidate.current_company_industry')
      register('candidate.current_company_position')
    }
  }, [register, type])
  const selectImageFile = event => {
    const file = event?.target?.files?.[0]
    console.log('[dev] ProfileDetail::selectImageFile::', file)
    if (file) {
      setCurrentImageFile(file)
      setPreviewImage(URL.createObjectURL(file))
    }
  }
  const selectVideoFile = event => {
    const file = event?.target?.files?.[0]
    console.log('[dev] ProfileDetail::selectVideoFile::', file)
    if (file) {
      setCurrentVideoFile(file)
      // setPreviewImage(URL.createObjectURL(file));
    }
  }
  const selectResumeFile = event => {
    const file = event?.target?.files?.[0]
    console.log('[dev] ProfileDetail::selectResumeFile::', file)
    if (file) {
      setCurrentResumeFile(file)
    }
  }
  const onChange = key => value => {
    clearErrors(key)
    setValue(key, value)
  }
  const handleSelectSubCategorie = cat => {
    if (rolesMap[cat]) {
      const newInt = { ...rolesMap }
      delete newInt[cat]
      setRolesMap(newInt)
    } else {
      const newInt = { ...rolesMap }
      newInt[cat] = true
      setRolesMap(newInt)
    }
  }
  const handleSelectCategorie = (cat, sub) => {
    if (interestsMap[cat]) {
      const newInt = { ...interestsMap }
      const updateList = []
      for (let i = 0; i < rolesMapList.length; i++) {
        const element = rolesMapList[i]
        const isHave = sub?.some(e => e?.name === element?.name)
        if (!isHave) {
          updateList.push(element)
        }
      }
      delete newInt[cat]
      setInterestsMap(newInt)
      setRolesMapList(updateList)
    } else {
      const newInt = { ...interestsMap }
      const newSub = rolesMapList.concat(sub)
      newInt[cat] = true
      setInterestsMap(newInt)
      setRolesMapList(newSub)
    }
  }
  const handleSaveProfile = (candidate, save) => {
    console.log(
      '[dev] ProfileDetail::handleSaveProfile::candidate',
      candidate,
      save
    )
    dispatch(
      candidateSaveRequest({
        candidate,
        save
      })
    )
  }
  const onSubmit = ({
    confirmPassword,
    contact_number,
    city,
    state,
    zip_code,
    candidate,
    company,
    employee,
    ...data
  }) => {
    const body = {
      type,
      ...data
    }
    const formData = new FormData()
    Object.keys(body).forEach(key => {
      formData.append(key, body[key])
    })
    const updateField = (key, val) => {
      if (val) {
        formData.append(key, val)
      }
    }
    if (type === 'Company') {
      if (currentImageFile) {
        updateField('company.photo', currentImageFile)
      }
      updateField('company.contact_number', contact_number)
      updateField('company.city', city)
      updateField('company.state', state)
      updateField('company.zip_code', zip_code)

      updateField('company.business_name', company?.business_name)
      updateField('company.business_type', company?.business_type)
      updateField('company.position', company?.position)
    } else if (type === 'Candidate') {
      if (currentImageFile) {
        updateField('candidate.photo', currentImageFile)
      }
      if (currentVideoFile) {
        updateField('candidate.video', currentVideoFile)
      }
      if (currentResumeFile) {
        updateField('candidate.resume_file', currentResumeFile)
      }
      updateField('candidate.contact_number', contact_number)
      updateField('candidate.city', city)
      updateField('candidate.state', state)
      updateField('candidate.zip_code', zip_code)

      const { currently_employed } = candidate
      if (currently_employed === 'True') {
        updateField('candidate.currently_employed', 'True')

        updateField(
          'candidate.current_company_name',
          candidate?.current_company_name
        )
        updateField(
          'candidate.current_company_industry',
          candidate?.current_company_industry
        )
        updateField(
          'candidate.current_company_position',
          candidate?.current_company_position
        )
      } else {
        updateField('candidate.currently_employed', 'False')
      }
    } else if (type === 'Employee') {
      updateField('employee.city', city)
      updateField('employee.contact_number', contact_number)
      updateField('employee.state', state)
      updateField('employee.zip_code', zip_code)
      updateField('employee.position', employee?.position)
      if (currentImageFile) {
        updateField('employee.photo', currentImageFile)
      }
    }

    console.log('[dev] ProfileDetail::onSubmit::formData', formData)

    if (isCandidateProfile) {
      const body = {
        candidate: {
          interests: Object.keys(interestsMap),
          roles: Object.keys(rolesMap),
          preferred_locations: preferedLocations
        }
      }

      dispatch(updateProfileJsonRequest(body))
    }

    if (type === 'Employee') {
      const employeeData = {
        first_name: body?.first_name,
        last_name: body?.last_name
      }
      dispatch(updateEmployee({ id, employeeData }))
        .then(unwrapResult)
        .then(data => {
          console.log('[dev]ProfileDetail::data', data)
          // dispatch(
          //   updateSnack({
          //     open: true,
          //     severity: 'success',
          //     message: 'Profile Saved'
          //   })
          // )
        })
        .catch(data => {
          console.log('[dev]ProfileDetail::catch::', data)
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: 'Error Saving Profile'
            })
          )
        })
    }

    dispatch(updateProfileRequest(formData))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]ProfileDetail::data', data)
        setEditMode(s => !s)
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'Profile Saved'
          })
        )
      })
      .catch(data => {
        console.log('[dev]ProfileDetail::catch::', data)
        dispatch(
          updateSnack({
            open: true,
            severity: 'error',
            message: 'Error Saving Profile'
          })
        )
      })
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [imageShow, setImageShow] = React.useState(null)
  const [imageShow1, setImageShow1] = React.useState(null)
  const [selectedName, setSelectedName] = React.useState('')

  const handleClick = (event, name) => {
    const imageshow =
      name === 'V - Engineering'
        ? Engineeringguide1
        : name === 'IX - Science'
        ? Scienceguide1
        : Legalguide
    const imageshow1 =
      name === 'V - Engineering' ? Engineeringguide2 : Scienceguide2
    setImageShow(imageshow)
    setSelectedName(name)
    setImageShow1(imageshow1)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function test (uri) {
    const url = uri
    const img = '<img src="' + url + '">'
    const popup = window.open()
    popup.document.write(img)
    popup.print()
  }

  const open = Boolean(anchorEl)
  const _id = open ? 'simple-popover' : undefined

  console.log('type', type)
  console.log('isProfile', isProfile)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      {isProfile && currentUser?.type !== 'Candidate' && (
        <Box sx={{ alignSelf: 'flex-end', p: 2, position: 'absolute' }}>
          <JobActionIcon
            onClick={() => {
              handleSaveProfile(id, !isSaved)
              setIsSaved(!isSaved)
            }}
          >
            {isSaved ? (
              <BookmarkIcon fill='black' height={20} width={20} />
            ) : (
              <Bookmark2Icon height={20} width={20} />
            )}
          </JobActionIcon>
        </Box>
      )}
      <ProfileBoxWrap sx={{ flexDirection: 'row' }}>
        <Grid xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirecion: 'row'
            }}
          >
            <input
              id='btn-upload-img'
              name='btn-upload-img'
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
              onChange={selectImageFile}
              ref={imgRef}
            />
            <input
              id='btn-upload-video'
              name='btn-upload-video'
              style={{ display: 'none' }}
              type='file'
              accept='video/*'
              onChange={selectVideoFile}
              ref={videoRef}
            />
            <input
              id='btn-upload-resume'
              name='btn-upload-resume'
              style={{ display: 'none' }}
              type='file'
              accept='.pdf'
              onChange={selectResumeFile}
              ref={resumeRef}
            />
            <IconButton
              className='btn-choose'
              variant='outlined'
              component='span'
              sx={{
                backgroundColor: '#F0F0F0',
                p: 0,
                border: 'none',
                width: '137px',
                height: '137px',
                '&:hover': {
                  border: 'none'
                }
              }}
              onClick={() => {
                if (editMode) {
                  imgRef?.current?.click()
                }
              }}
            >
              <Avatar
                alt='Remy Sharp'
                src={previewImage || photo}
                sx={{
                  //
                  width: '137px',
                  height: '137px'
                }}
              />
            </IconButton>
            <Box
              sx={{
                //
                ml: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {!editMode && (
                <>
                  <ProfileTextSubTitle
                    sx={{
                      //
                      fontSize: '1.5rem'
                    }}
                  >{`${first_name} ${last_name}`}</ProfileTextSubTitle>
                  <ProfileTextTitle sx={{ mt: 0 }}>
                    {business_type ||
                      current_company_industry ||
                      employee?.company?.business_type ||
                      ''}
                  </ProfileTextTitle>
                </>
              )}
              {(isProfile && (
                <Box>
                  <UIButton
                    color='primary'
                    sx={{ py: 1, px: 3, fontSize: '0.75rem' }}
                    onClick={() => {
                      navigate(`/app/chat/${id}`)
                    }}
                  >
                    Message
                  </UIButton>
                  {currentUser?.type !== 'Candidate' && (
                    <>
                      <UIButton
                        disabled={!video}
                        sx={{ ml: 2, py: 1, px: 3, fontSize: '0.75rem' }}
                        onClick={() => {
                          if (video) {
                            window.open(video)
                          }
                        }}
                      >
                        View Video
                      </UIButton>
                      <UIButton
                        disabled={!resume_file}
                        sx={{ ml: 2, py: 1, ml: 2, px: 2, fontSize: '0.75rem' }}
                        onClick={() => {
                          if (resume_file) {
                            window.open(resume_file)
                          }
                        }}
                      >
                        View Resume
                      </UIButton>
                    </>
                  )}
                </Box>
              )) || (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {editMode && (
                    <>
                      {/* <ProfileTextSubTitle
                        sx={{
                          //
                          fontSize: '20px',
                        }}
                      >
                        Upload a new profile picture
                      </ProfileTextSubTitle> */}
                      <Box>
                        <UIButton
                          // color="primary"
                          sx={{
                            py: 1,
                            px: 3,
                            my: 0,
                            mt: 1,
                            fontSize: '0.75rem'
                          }}
                          onClick={() => {
                            imgRef?.current?.click()
                          }}
                        >
                          Choose Profile Picture
                        </UIButton>
                      </Box>
                      {!isProfile && type === 'Candidate' && (
                        <Box>
                          <UIButton
                            // color="primary"
                            sx={{
                              py: 1,
                              px: 3,
                              my: 0,
                              mt: 1,
                              fontSize: '0.75rem'
                            }}
                            onClick={() => {
                              videoRef?.current?.click()
                            }}
                          >
                            Choose Video
                          </UIButton>
                          <UIButton
                            // color="primary"
                            sx={{
                              py: 1,
                              px: 3,
                              my: 0,
                              mt: 1,
                              ml: 2,
                              fontSize: '0.75rem'
                            }}
                            onClick={() => {
                              resumeRef?.current?.click()
                            }}
                          >
                            Choose Resume
                          </UIButton>
                        </Box>
                      )}
                    </>
                  )}
                  <Box>
                    <UIButton
                      color='primary'
                      sx={{ py: 1, px: 3, fontSize: '0.75rem' }}
                      onClick={() => {
                        // setEditMode((s) => !s);
                        if (editMode) {
                          handleSubmit(onSubmit)()
                        } else {
                          setEditMode(s => !s)
                        }
                      }}
                    >
                      {`${editMode ? 'Update' : 'Edit'} Profile`}
                    </UIButton>
                  </Box>
                  <Box>
                    {isCandidateProfile && !editMode && (
                      <UIButton
                        disabled={!video}
                        sx={{ ml: 0, py: 1, px: 3, fontSize: '0.75rem' }}
                        onClick={() => {
                          if (video) {
                            window.open(video)
                          }
                        }}
                      >
                        View Video
                      </UIButton>
                    )}
                    {isCandidateProfile && !editMode && (
                      <UIButton
                        disabled={!resume_file}
                        sx={{ ml: 0, py: 1, ml: 2, px: 3, fontSize: '0.75rem' }}
                        onClick={() => {
                          if (resume_file) {
                            window.open(resume_file)
                          }
                        }}
                      >
                        View Resume
                      </UIButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid xs={6}>
          {editMode && (
            <Box>
              <UITextField
                id='first_name'
                label='First Name'
                placeholder='First Name'
                defaultValue={first_name}
                onChange={onChange('first_name')}
                error={errors?.first_name}
              />
              <UITextField
                id='last_name'
                label='Last Name'
                placeholder='Last Name'
                defaultValue={last_name}
                onChange={onChange('last_name')}
                error={errors?.last_name}
              />
            </Box>
          )}
        </Grid>
      </ProfileBoxWrap>
      {(isCandidateProfile && editMode && (
        <ProfileBox>
          <UIRadio
            id='candidate.currently_employed'
            label='Are you currently employed?'
            options={[
              { label: 'Yes', value: 'True' },
              { label: 'No', value: 'False' }
            ]}
            onChange={onChange('candidate.currently_employed')}
            defaultValue={getValues('candidate.currently_employed')}
            error={errors?.candidate?.currently_employed}
          />
          <Collapse
            in={getValues('candidate.currently_employed') === 'True'}
            sx={{ gridColumn: 'span 2' }}
          >
            <Box
              sx={{
                gridColumn: 'span 2',
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
                mt: 2
              }}
            >
              <UITextField
                id='candidate.current_company_name'
                label='Company Name'
                placeholder='Current Company Name'
                onChange={onChange('candidate.current_company_name')}
                value={getValues('candidate.current_company_name')}
                error={errors?.candidate?.current_company_name}
                sx={{ gridColumn: 'span 2' }}
              />
              <UITextField
                select
                label='Industry'
                id='candidate.current_company_industry'
                onChange={onChange('candidate.current_company_industry')}
                value={getValues('candidate.current_company_industry')}
                error={errors?.candidate?.current_company_industry}
              >
                {industries?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </UITextField>
              <UITextField
                select
                label='Your Position'
                id='candidate.current_company_position'
                onChange={onChange('candidate.current_company_position')}
                value={getValues('candidate.current_company_position')}
                error={errors?.candidate?.current_company_position}
              >
                {positions?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </UITextField>
            </Box>
          </Collapse>
        </ProfileBox>
      )) ||
        (isCandidateProfile && currently_employed && (
          <ProfileBox>
            <ProfileTextSection
              id='candidate.current_company_name'
              label='Company Name'
              value={current_company_name}
              editMode={editMode}
            />

            <ProfileTextSection
              id='candidate.current_company_industry'
              label='Industry'
              value={current_company_industry}
              editMode={editMode}
            />

            <ProfileTextSection
              id='candidate.current_company_position'
              label='Your Position'
              value={current_company_position}
              editMode={editMode}
            />
          </ProfileBox>
        )) ||
        (!isCandidateProfile && (
          <ProfileBox>
            <ProfileTextSection
              id='company.business_name'
              label='Company Name'
              value={
                type === 'Employee'
                  ? employee?.company?.business_name
                  : business_name
              }
              editMode={editMode}
            >
              <UITextField
                id='company.business_name'
                // label="Business Name"
                placeholder='Business Name'
                // icon={<SuitcaseIcon />}
                onChange={onChange('company.business_name')}
                value={getValues('company.business_name')}
                error={errors?.company?.business_name}
              />
            </ProfileTextSection>
            <ProfileTextSection
              id='position'
              label='Work Position'
              value={position}
              editMode={editMode}
            >
              <UITextField
                select
                // label="Your Position"
                id='company.position'
                // icon={<TeamIcon />}
                onChange={onChange(
                  type === 'Employee' ? 'employee.position' : 'company.position'
                )}
                value={getValues(
                  type === 'Employee' ? 'employee.position' : 'company.position'
                )}
                error={
                  type === 'Employee'
                    ? errors?.employee?.position
                    : errors?.company?.position
                }
              >
                {positions?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </UITextField>
            </ProfileTextSection>
            <ProfileTextSection
              id='company.business_type'
              label='Industry'
              value={
                type === 'Employee'
                  ? employee?.company?.business_type
                  : business_type
              }
              editMode={editMode}
            >
              <UITextField
                select
                // label="Industry"
                id='company.business_type'
                // icon={<SuitcaseIcon />}
                onChange={onChange('company.business_type')}
                value={getValues('company.business_type')}
                error={errors?.company?.business_type}
              >
                {industries?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </UITextField>
            </ProfileTextSection>
          </ProfileBox>
        ))}

      {(isCandidateProfile && (
        <ProfileBox>
          <ProfileTextSection
            id='email'
            label='Email Address'
            value={getValues('email')}
            defaultValue={email}
            editMode={editMode}
            placeholder='Enter your email address'
            onChange={onChange('email')}
            error={errors?.email}
          />
          <ProfileTextSection
            id='contact_number'
            label='Contact Number'
            value={contact_number}
            editMode={editMode}
          >
            <UITextFieldMask
              id='contact_number'
              defaultValue={contact_number}
              placeholder='Enter Contact Number'
              onChange={onChange('contact_number')}
              error={errors?.contact_number}
              opts={{ mask: '(000) 000-0000' }}
            />
          </ProfileTextSection>
        </ProfileBox>
      )) || (
        <ProfileBox>
          <ProfileTextSection
            id='email'
            label='Business Email'
            value={getValues('email')}
            defaultValue={email}
            editMode={editMode}
            placeholder='Enter your email address'
            onChange={onChange('email')}
            error={errors?.email}
          />
          <ProfileTextSection
            id='contact_number'
            label='Business Number'
            value={contact_number}
            editMode={editMode}
          >
            <UITextFieldMask
              id='contact_number'
              defaultValue={contact_number}
              placeholder='Enter Business Number'
              onChange={onChange('contact_number')}
              error={errors?.contact_number}
              opts={{ mask: '(000) 000-0000' }}
            />
          </ProfileTextSection>
        </ProfileBox>
      )}

      {isCandidateProfile && (currentUser?.type !== 'Candidate' || !isProfile) && (
        <ProfileBox>
          <ProfileTextTitle>Interests</ProfileTextTitle>
          {(editMode && (
            <Box sx={{ mb: 3 }}>
              {categories?.map(({ name, subcategories }) => {
                return (
                  <>
                    <JobCategoryButton
                      key={name}
                      color='primary'
                      active={interestsMap[name]}
                      onClick={() => {
                        handleSelectCategorie(name, subcategories)
                      }}
                    >
                      {name}
                    </JobCategoryButton>
                    {(name === 'V - Engineering' ||
                      name === 'VIII - Legal' ||
                      name === 'IX - Science') && (
                      <Button
                        aria-describedby={_id}
                        className='gPopup'
                        onClick={e => handleClick(e, name)}
                      >
                        G
                      </Button>
                    )}
                  </>
                )
              })}
              <Popover
                id={_id}
                open={open}
                anchorEl={anchorEl}
                style={{ width: '90%', top: 50 }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <div className='rowBetween'>
                  <Typography sx={{ p: 2 }}>
                    {selectedName === 'V - Engineering'
                      ? 'Engineering'
                      : selectedName === 'VIII - Legal'
                      ? 'Legal'
                      : 'Science'}{' '}
                    guide
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className='rowBetween'>
                  <Popup label={selectedName} />
                </div>
              </Popover>
            </Box>
          )) || (
            <Box sx={{ mb: 3 }}>
              {interests?.map(name => (
                <JobCategoryButton
                  key={name}
                  color='primary'
                  active={false}
                  onClick={() => {
                    // handleSelectCategorie(name);
                  }}
                >
                  {name}
                </JobCategoryButton>
              ))}
            </Box>
          )}
          <ProfileTextTitle>Sub Categories</ProfileTextTitle>
          {(editMode && (
            <Box sx={{ mb: 3 }}>
              {rolesMapList?.map(({ name }) => (
                <JobCategoryButton
                  key={name}
                  color='primary'
                  active={rolesMap[name]}
                  onClick={() => {
                    handleSelectSubCategorie(name)
                  }}
                >
                  {name}
                </JobCategoryButton>
              ))}
            </Box>
          )) || (
            <Box sx={{ mb: 3 }}>
              {roles?.map(name => (
                <JobCategoryButton
                  key={name}
                  color='primary'
                  active={false}
                  onClick={() => {
                    // handleSelectCategorie(name);
                  }}
                >
                  {name}
                </JobCategoryButton>
              ))}
            </Box>
          )}
          <ProfileTextTitle>Prefered Locations</ProfileTextTitle>
          {(editMode && (
            <UITextField
              id='state'
              label='State'
              multiple
              placeholder='Enter your state'
              icon={<LocationIcon />}
              sx={{ gridColumn: 'span 2' }}
              onChange={value => {
                setPreferedLocations(
                  typeof value === 'string' ? value.split(',') : value
                )
              }}
              value={preferedLocations}
              // error={errors?.state}
              select
            >
              <MenuItem value=''>
                <em>Select your state</em>
              </MenuItem>
              {STATE_TYPES.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </UITextField>
          )) || (
            <Box sx={{ mb: 3 }}>
              {preferred_locations?.map(name => (
                <JobCategoryButton
                  key={name}
                  color='primary'
                  active={false}
                  onClick={() => {
                    // handleSelectCategorie(name);
                  }}
                >
                  {name}
                </JobCategoryButton>
              ))}
            </Box>
          )}
        </ProfileBox>
      )}
      <ProfileBox sx={{ borderBottom: 'non', mb: 5 }}>
        <ProfileTextTitle>Location</ProfileTextTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          {editMode ? (
            <Box sx={{ flex: '1 1 0', mx: 1 }}>
              <UITextField
                id='state'
                label='State'
                placeholder='Enter your state'
                icon={<LocationIcon />}
                sx={{ gridColumn: 'span 2' }}
                onChange={onChange('state')}
                value={getValues('state')}
                error={errors?.state}
                select
              >
                <MenuItem value=''>
                  <em>Select your state</em>
                </MenuItem>
                {STATE_TYPES.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </UITextField>
            </Box>
          ) : (
            <ProfileTextSmallSection
              label='State:'
              value={getValues('state')}
              editMode={editMode}
              id='state'
              defaultValue={state}
              placeholder='State'
              onChange={onChange('state')}
              error={errors?.state}
            />
          )}
          <ProfileTextSmallSection
            label='City:'
            value={getValues('city')}
            editMode={editMode}
            id='city'
            defaultValue={city}
            placeholder='City'
            onChange={onChange('city')}
            error={errors?.city}
          />
          <ProfileTextSmallSection
            label='Zip Code:'
            value={getValues('zip_code')}
            editMode={editMode}
            id='zip_code'
            defaultValue={zip_code}
            placeholder='Zip Code'
            onChange={onChange('zip_code')}
            error={errors?.zip_code}
          />
        </Box>
      </ProfileBox>
    </Box>
  )
}
export default ProfileDetail
